/**
 * Update order.
 *
 * @param order
 */
let updateRequest;

function updateOrder(shippingOptionCheck = false) {
    if (updateRequest) {
        updateRequest.abort();
    }

    const $order = $('#order');
    const $form = $('#checkoutForm');
    const $passwordWrapper = $('#passwordWrapper');
    const $shippingOption = $form.find('input[name="shipping_option"]:checked');
    const $paymentMethod = $form.find('input[name="payment_method"]:checked');
    const $createAccount = $form.find('input[name="create_account"]');
    const $paymentMethodOnPickup = $form.find('input[name="payment_method"][value="on-pickup"]');
    const $existingBilling = $form.find('select[name=existing_billing_address]').find('option:selected');
    const $existingShipping = $form.find('select[name=existing_shipping_address]').find('option:selected');
    const $billingAddressOptionNew = $form.find('#billingAddressOptionNew');
    const $shippingAddressOptionNew = $form.find('#shippingAddressOptionNew');

    const data = {};
    switch ($shippingOption.val()) {
        case 'billing_address':
            if ($billingAddressOptionNew.is(':checked')) {
                data.country = $form.find('#billing_country').val();
            } else {
                if($billingAddressOptionNew.length > 0){
                    data.country = $existingBilling.data('country');
                }else{
                    data.country = $form.find('#billing_country').val();
                }
            }
            break;
        case 'shipping_address':
            if ($shippingAddressOptionNew.is(':checked')) {
                data.country = $form.find('#shipping_country').val();
            } else {
                if($billingAddressOptionNew.length > 0){
                    data.country = $existingShipping.data('country');
                }else{
                    data.country = $form.find('#shipping_country').val();
                }
            }
            break;
        case 'pickup_point':
            if ($billingAddressOptionNew.is(':checked')) {
                console.log(1);
                data.country = $form.find('#billing_country').val();
            }else{
                if($existingBilling.length > 0){
                    console.log(2);
                    data.country = $existingBilling.data('country');
                }else{
                    console.log(3);
                    data.country = $form.find('#billing_country').val();
                }
            }
            break;
    }

    console.log(data);

    switch ($shippingOption.val()) {
        case 'pickup_point':

            $('#pickup').removeClass('d-none');
            if (!$('input[name="pickup_house_number"]').val()) {
                $('input[name="pickup_house_number"]').val($('input[name="billing_house_number"]').val());
            }
            if (!$('input[name="pickup_postal_code"]').val()) {
                $('input[name="pickup_postal_code"]').val($('input[name="billing_postal_code"]').val());
            }
            if (shippingOptionCheck) {
                getPickupLocation()
            }
            break;
        case 'pickup':
            $('#pickup').addClass('d-none');
            $paymentMethodOnPickup.prop('disabled', false);
            $paymentMethodOnPickup.closest('label')
                .parent()
                .show();

            break;
        default:
            if ($paymentMethod.val() === 'on-pickup') {
                $('input[name="payment_method"]:first').prop('checked', true);
            }
            $('#pickup').addClass('d-none');
            $paymentMethodOnPickup.prop('disabled', true);
            $paymentMethodOnPickup.closest('label')
                .parent()
                .hide();
            break;
    }


    if ($createAccount.prop('checked') === true) {
        $passwordWrapper.find('input').attr('disabled', false);
        $passwordWrapper.show();
        $('input[name="newsletter"]').attr('checked', true);
    } else {
        $passwordWrapper.hide();
        $passwordWrapper.find('input').attr('disabled', true);
        $('input[name="newsletter"]').attr('checked', false);
    }

    data.shipping_option = $shippingOption.val();
    data.payment_method = $paymentMethod.val();
    data.create_account = $createAccount.prop('checked') === true ? 'true' : 'false';
    console.log(url.order.data); 
    updateRequest = $.ajax({
        type: 'GET',
        data,
        url: url.order.data,
        dataType: 'json',
        success: function (order) {
            if ($order.length) {
                if (order.rows.length) {
                    var template = $('script[data-template="order-container"]')
                    const $shipping = $('<div/>', { class: 'row shipping' });

                    if(!order.free_shipping){

                        if($form.find('#billing_country').val() !== null ||
                            $form.find('select[name=existing_billing_address]').length > 0 ||
                            $form.find('select[name=existing_shipping_address]').length > 0){
                            const shippingText = (order.language == 'nl' ? 'Verzendkosten' : 'Shipping costs')
                            $shipping.append($('<div/>', { class: 'col-xs-8' }).html('<strong>' + shippingText + '</strong>'))
                            .append($('<div/>', { class: 'col-xs-4 text-right' }).text(order.shipping_fee));
                        }else{
                            const shippingText = (order.language == 'nl' ? 'Vul je adres in om de verzendkosten te berekenen' : 'Add your address to calculate shipping costs')
                            $shipping.append($('<div/>', { class: 'col-xs-8' }).html('<strong>' + shippingText + '</strong>'))
                                .append($('<div/>', { class: 'col-xs-4 text-right' }).text(''));
                        }
                    }
                    const containerTemplate = template.text();

                    var tax_rate = 0;
                    var tax_calculated = 0;

                    const $tax = $('<div/>', { class: 'row tax' });

                    order.tax_data.forEach(function (entry) {
                        tax_rate = entry.rate;
                        tax_calculated += entry.calculated;

                        const taxTotalText = (order.language == 'nl' ? 'Btw totaal' : 'Tax total');

                        $tax.append($('<div/>', { class: 'col-xs-8' }).html('<strong>' + taxTotalText + ' (' + entry.rate + '%)</strong>'))
                            .append($('<div/>', { class: 'col-xs-4 text-right' }).text(entry.calculated));
                    });


                    const $discount = $('<div/>');

                    if (null !== order.discount_coupon) {
                        const $discountCouponDiscount = $('<div/>', { class: 'row discount' });

                        if (order.has_discount_coupon_discount) {
                            $discountCouponDiscount.append($('<div/>', { class: 'col-xs-8' }).text('Korting: ' + order.discount_coupon.code.toUpperCase()))
                                .append($('<div/>', { class: 'col-xs-4 text-right' }).text('- ' + order.discount_coupon_discount));
                        }

                        $discount.append($discountCouponDiscount);
                    }

                    if (order.has_club_discount) {
                        const $clubDiscount = $('<div/>', { class: 'row discount' });

                        $clubDiscount.append($('<div/>', { class: 'col-xs-8' }).text(order.club_level))
                            .append($('<div/>', { class: 'col-xs-4 text-right' }).text('- ' + order.club_discount));

                        $discount.append($clubDiscount);
                    }

                    if (order.has_product_tier_discount) {
                        const $productTierDiscount = $('<div/>', { class: 'row discount' });

                        $productTierDiscount.append($('<div/>', { class: 'col-xs-8' }).text('Actie korting'))
                            .append($('<div/>', { class: 'col-xs-4 text-right' }).text('- ' + order.product_tier_discount));

                        $discount.append($productTierDiscount);
                    }

                    const $handling = $('<div/>');
                    if (order.handling_fee != '€ 0,00') {
                        $handling.addClass('row transaction');
                        $handling.append($('<div/>', { class: 'col-xs-8' }).html('<strong>Transactiekosten</strong>'))
                            .append($('<div/>', { class: 'col-xs-4 text-right' }).text(order.handling_fee));
                    }

                    // Check if country picked
                    if($form.find('#billing_country').val() !== null
                        || $form.find('select[name=existing_billing_address]').length > 0 ||
                        $form.find('select[name=existing_shipping_address]').length > 0){
                        var totalCheck = order.total_plus_shipping;
                    }else{
                        var totalCheck = order.total;
                    }

                    const $container = $(containerTemplate.replace(/{{ id }}/g, order.id)
                        .replace(/{{ count }}/g, order.count)
                        .replace(/{{ subtotal }}/g, order.total_without_discount)
                        .replace(/{{ discount }}/g, $discount[0].outerHTML)
                        .replace(/{{ tax }}/g, $tax[0].outerHTML)
                        .replace(/{{ tax_caculated }}/g, tax_calculated)
                        .replace(/{{ shipping_costs }}/g, $shipping[0].outerHTML)
                        .replace(/{{ handling_fee }}/g, $handling[0].outerHTML)
                        .replace(/{{ total }}/g, totalCheck));
                    // order.free_shipping ? '<span class="free-shipping">Gratis</span>' :
                    $order.empty()
                        .append($container);

                    const $orderRows = $('.order-rows');
                    $orderRows.empty();
                    const rowTemplate = $('script[data-template="order-row"]').text();
                    $.each(order.rows, function (index, row) {
                        let options = '';
                        let fields = '';
                        if (row.variant.options.length) {
                            const optionFieldTemplate = '<input type="hidden" name="options[{{ option }}]" value="{{ value }}">';
                            options += '<div class="options">';
                            $.each(row.variant.options, function (index, option) {
                                options += '<span class="option">' + option.value.name + '</span>';
                                fields += optionFieldTemplate.replace(/{{ option }}/g, option.id)
                                    .replace(/{{ value }}/g, option.value.id);
                            });
                            options += '</div>';
                        }

                        let label = '';
                        if (row.variant.product.label !== null && row.variant.product.label.key === 'pre-order') {
                            label += '<small style="margin-left: 10px;">(Pre-order)</small>';
                        }

                        const $row = $(rowTemplate.replace(/{{ id }}/g, row.id)
                            .replace(/{{ variant.product.image }}/g, (row.variant.product.image ? row.variant.product.image : '/img/nophoto.png'))
                            .replace(/{{ variant.product.name }}/g, row.variant.product.name)
                            .replace(/{{ variant.options }}/g, options)
                            .replace(/{{ variant.sku }}/g, row.variant.sku)
                            .replace(/{{ label }}/g, label)
                            .replace(/{{ variant.price }}/g, row.variant.price)
                            .replace(/{{ quantity }}/g, row.quantity)
                            .replace(/{{ fields }}/g, fields)
                            .replace(/{{ subtotal }}/g, row.subtotal)
                            .replace(/{{ url.product.show }}/g, row.variant.product.link));

                        $orderRows.append($row);
                    });

                    $orderRows.trigger('order.loaded');

                    const checkoutButton = $('#checkoutButton');
                    const excludeCountryMessage = $('#excludeCountryMessage');
                    const excludeCountryMessage2 = $('#excludeCountryMessage2');

                    if(order.exclude_country_message !== null){
                        checkoutButton.attr('disabled', true);
                        excludeCountryMessage.text(order.exclude_country_message).removeClass('d-none');
                        excludeCountryMessage2.text(order.exclude_country_message).removeClass('d-none');
                    } else {
                        checkoutButton.attr('disabled', false);
                        excludeCountryMessage.text('').addClass('d-none');
                        excludeCountryMessage2.text('').addClass('d-none');
                    }
                }

                initDiscount(order);
            }
        }
    });
}

function showAndEnable($element) {
    $element.show();
    $element.find('radio, input, select').prop('disabled', false);
}

function hideAndDisable($element) {
    $element.hide();
    $element.find('radio, input, select').prop('disabled', true);
}

function updateBillingAddress() {
    const $existingBillingAddress = $('#existingBillingAddress');
    const $newBillingAddress = $('#newBillingAddress');
    const value = $('input[name="billing_address_option"]:checked').val();
    switch (value) {
        case 'existing':
            hideAndDisable($newBillingAddress);
            showAndEnable($existingBillingAddress);
            break;
        case 'new':
            hideAndDisable($existingBillingAddress);
            showAndEnable($newBillingAddress);
            break;
    }
}

function updateShippingAddress() {
    const $existingShippingAddress = $('#existingShippingAddress');
    const $newShippingAddress = $('#newShippingAddress');
    const value = $('input[name="shipping_address_option"]:checked').val();
    switch (value) {
        case 'existing':
            hideAndDisable($newShippingAddress);
            showAndEnable($existingShippingAddress);
            break;
        case 'new':
            console.log('new');
            hideAndDisable($existingShippingAddress);
            showAndEnable($newShippingAddress);
            break;
    }
}

// Uitlezen en bepalen van adresgegevens
function checkOrderPostalZip(type) {
    const country = $('select[name="' + type + '_country"]').val();
    const postalCode = $('input[name="' + type + '_postal_code"]').val();
    const houseNumber = $('input[name="' + type + '_house_number"]').val();
    if (country === 'NL') {
        if (postalCode !== '' && houseNumber !== '') {
            getOrderAdressByPostalZip(postalCode, houseNumber, type);
        }
    }
}

// Aanroepen van de postcode api
function getOrderAdressByPostalZip(postalCode, houseNumber, type) {
    postalCode = cleanUpZipcode(postalCode);
    $.ajax({
        url: '/postcode/address/' + postalCode + '/' + houseNumber,
        type: 'GET',
        async: true,
        success: function (result) {
            if (result.error) {
                handleOrderAdressRequestData(result, type, result.error);
            } else {
                handleOrderAdressRequestData(result, type, false);
            }
        }
    });
}

// Timeout
var orderDelayGetAdress = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

function handleOrderAdressRequestData(adress, type, error) {
    if (error) {
        $('input[name="' + type + '_street"]').attr('readonly', false);
        $('input[name="' + type + '_city"]').attr('readonly', false);
    } else {
        $('input[name="' + type + '_street"]').val(adress.street).attr('readonly', true);
        $('input[name="' + type + '_city"]').val(adress.city).attr('readonly', true);

        $('input[name="' + type + '_street"], input[name="' + type + '_city"]').closest('.form-group').addClass('active');
    }


}

// Opschonen van de postcode
function cleanUpZipcode(zipcode) {
    var newZipcode = zipcode.trim();
    newZipcode = newZipcode.replace(/ /g, '');
    newZipcode = newZipcode.toUpperCase();
    return newZipcode;
}

function initDiscount(order) {
    const $discountCoupon = $('.discount-coupon');
    $discountCoupon.empty();

    var add = (order.language == 'nl' ? 'Toevoegen' : 'Add')

    if (null === order.discount_coupon) {
        const $discountCouponFormGroup = $('<div/>', { class: 'form-group' })
            .append($('<input/>', {
                type: 'text',
                name: 'code',
                class: 'form-control required'
            }))
            .append($('<button/>', {
                type: 'button',
                class: 'btn primary'
            }).attr('data-action', 'add-discount-coupon')
                .text(add))
            .append($('<b/>', { class: 'message' }));

        const $discountCouponForm = $('<form>', { id: 'addDiscountCouponForm' }).append($discountCouponFormGroup);

        $discountCoupon.html($discountCouponForm);

        $('[data-action="add-discount-coupon"]').off().on('click', function (e) {
            e.preventDefault();

            const $container = $('.discount-coupon');
            const $input = $container.find('input[name="code"]');
            const $message = $container.find('.message');
            const code = $input.val();

            $message.removeClass('text-danger')
                .addClass('text-success')
                .text('De kortingscode wordt toegepast, een ogenblik geduld...');

            $.ajax({
                type: 'POST',
                url: url.cart.discountCoupon,
                data: {
                    code
                },
                dataType: 'json',
                success: function (cart) {
                    updateOrder();
                },
                error: function (e) {
                    const message = e.responseJSON && 'message' in e.responseJSON ? e.responseJSON.message : 'Er is iets misgegaan.';

                    $container.addClass('has-error');

                    $message.removeClass('text-success')
                        .addClass('text-danger')
                        .text(message);
                }
            });
        });

        $('#addDiscountCouponForm').off().on('submit', function (e) {
            e.preventDefault();

            $('[data-action="add-discount-coupon"]').trigger('click');
        });
    } else {
        $discountCoupon.append($('<div/>', { class: 'row' })
            .append($('<div/>', { class: 'col-xs-8' }).html('<span class="code"><b>' + order.discount_coupon.code.toUpperCase() + '</b> toegepast</span>'))
            .append($('<div/>', { class: 'col-xs-4' }).append($('<a/>', { class: 'remove' }).attr('data-action', 'remove-discount-coupon')
                .html('&times;'))));

        $('[data-action="remove-discount-coupon"]').off().on('click', function (e) {
            e.preventDefault();
            $.ajax({
                type: 'DELETE',
                url: url.cart.discountCoupon,
                dataType: 'json',
                success: function (cart) {
                    updateOrder();
                }
            });
            // shipping_option
        });
    }
}

function getPickupLocation() {
    const existingBilling = $('#checkoutForm select[name=existing_billing_address]').find('option:selected');
    const existingShipping = $('#checkoutForm select[name=existing_shipping_address]').find('option:selected');

    if(existingBilling.length > 0){
        var country = existingBilling.data('country');
    }else if(existingShipping.length > 0){
        var country = existingShipping.data('country');
    }else if($('select[name="billing_country"]').val() !== null){
        var country = $('select[name="billing_country"]').val();
    }else{
        var country = $('select[name="shipping_country"]').val();
    }

    var housenumber = $('input[name="pickup_house_number"]').val();
    var postalcode = $('input[name="pickup_postal_code"]').val();

    const loader = $('.location-loader');

    const pickup_locations_wrapper = $('.pickup_locations .locations-wrapper .locations-push');

    pickup_locations_wrapper.empty();

    loader.removeClass('d-none')

    $.ajax({
        type: 'POST',
        data: {
            'housenumber': housenumber,
            'postalcode': postalcode,
            'country': country
        },
        dataType: 'json',
        url: '/nl/pickuppoint',
        success: function (result) {
            console.log(result);
            var timer = window.setTimeout(function (e) {
                if (result.success) {
                    clearTimeout(timer);
                    loader.addClass('d-none')
                    var pickup_locations = result.pickup_points.data.pickup_locations

                    const pickupTemplate = $('script[data-template="pickup-location"]').text();
                    $('#totalLocation').text(pickup_locations.length)
                    pickup_locations.forEach(function (item) {
                        const row = $(pickupTemplate.replace(/{{ location.name }}/g, item.location.location_name)
                            .replace(/{{ location.code }}/g, item.location.location_code)
                            .replace(/{{ location.distance }}/g, (item.location.distance / 1000).toFixed(2) + ' km')
                            .replace(/{{ location.city }}/g, item.address.city)
                            .replace(/{{ location.country }}/g, item.address.cc)
                            .replace(/{{ location.address }}/g, item.address.street + ' ' + item.address.number + ' ' + item.address.number_suffix));

                        pickup_locations_wrapper.append(row);

                    })
                } else {
                    clearTimeout(timer);
                    loader.addClass('d-none')
                    var col = $('<div class="col-xs-12"></div>')
                    var p = $('<p></p>');
                    p.text('Geen PostNL-punt gevonden')
                    col.append(p)
                    pickup_locations_wrapper.append(col)
                }
            }, 500)
        }

    })
}

function getDetails(shippingOption){
    const $form = $('#checkoutForm');

    var details = {
        email: null,
        name: null,
        street: null,
        houseNumber: null,
        houseNumberAddition: null,
        postalCode: null,
        city: null,
        country: null
    };

    switch (shippingOption) {
        case 'billing_address':
            details.email = $form.find('#email').val();
            details.name = $form.find('#billing_first_name').val() + " " + $form.find('#billing_prefix').val() + " " + $form.find('#billing_last_name').val();
            details.street = $form.find('#billing_street').val();
            details.houseNumber = $form.find('#billing_house_number').val();
            details.houseNumberAddition = $form.find('#billing_house_number_addition').val();
            details.postalCode = $form.find('#billing_postal_code').val();
            details.city = $form.find('#billing_city').val();
            details.country = $form.find('#billing_country').val();
            break;
        case 'shipping_address':
            details.email = $form.find('#email').val();
            details.name = $form.find('#shipping_first_name').val() + " " + $form.find('#shipping_prefix').val() + " " + $form.find('#shipping_last_name').val();
            details.street = $form.find('#shipping_street').val();
            details.houseNumber = $form.find('#shipping_house_number').val();
            details.houseNumberAddition = $form.find('#shipping_house_number_addition').val();
            details.postalCode = $form.find('#shipping_postal_code').val();
            details.city = $form.find('#shipping_city').val();
            details.country = $form.find('#shipping_country').val();
            break;
    }

    var detailsHtml = '<table>\n' +
        '                 <tr>\n' +
        '                     <td width="120" style="vertical-align: top; padding-bottom: 15px;">Naam:</td>\n' +
        '                     <td style="vertical-align: top; padding-bottom: 15px;">' + details.name + '</td>\n' +
        '                 </tr>\n' +
        '                 <tr>\n' +
        '                     <td width="120" style="vertical-align: top; padding-bottom: 15px">Adres:</td>\n' +
        '                     <td style="vertical-align: top; padding-bottom: 15px">' + details.street + " " + details.houseNumber + (details.houseNumberAddition ? "-" + details.houseNumberAddition : "") + "<br>" +
                                    details.postalCode + " " + details.city + "<br>" +
                                    details.country + '</td>\n' +
        '                 </tr>\n' +
        '                 <tr>\n' +
        '                     <td width="120" style="vertical-align: top; padding-bottom: 15px">E-mail:</td>\n' +
        '                     <td style="vertical-align: top; padding-bottom: 15px">' + details.email + '</td>\n' +
        '                 </tr>\n' +
        '             </table>';

    return detailsHtml;
}


$(document).ready(function () {

    var billingPrefix = $('#billing_prefix, #billing_first_name, #billing_last_name');

    billingPrefix.bind('input paste', function (e) {
        var node = $(this);
        node.val(node.val().replace(/[^a-zA-Z ]/g, ''));
    });

    if ($('#order').length) {
        $('input[name="payment_method"]:first').prop('checked', true);

        $('#billing_country, #shipping_country, input[name="payment_method"]').on('change', function () {
            updateOrder();
        });
        $('input[name="shipping_option"]').on('change', function () {
            updateOrder(true);
            updateShippingAddress();
        });
        $('#billing_country').on('change', function () {
            if ($(this).val() !== 'NL') {
                if ($(this).val() !== 'BE') {
                    $('#shippingOptionPickupPointChoice').addClass('d-none');
                }

                $('input[name="billing_street"]').attr('readonly', false);
                $('input[name="billing_city"]').attr('readonly', false);

                if ($('input[name="shipping_option"]:checked').val() == 'pickup_point') {
                    $('#shippingOptionBillingAddress').prop("checked", true);
                }
            } else {
                if ($(this).val() !== 'BE') {
                    $('input[name="billing_street"]').attr('readonly', true);
                    $('input[name="billing_city"]').attr('readonly', true);

                    $('#shippingOptionPickupPointChoice').removeClass('d-none');
                }
            }
            updateOrder();
        })
        $('input[name="billing_address_option"]').on('change', function () {
            updateOrder();
            updateBillingAddress();
        });

        $('input[name="shipping_address_option"]').on('change', function () {
            updateOrder();
            updateShippingAddress();
        });

        $('input[name="create_account"]').on('change', function () {
            updateOrder();
        });

        $('#searchPickupPoint').on('click', function (e) {
            e.preventDefault();
            var housenumber = $('input[name="pickup_house_number"]');
            var postalcode = $('input[name="pickup_postal_code"]');
            var error = false;
            if (!housenumber.val()) {
                housenumber.addClass('is-invalid')
                error = true
            } else {
                housenumber.removeClass('is-invalid')
            }
            if (!postalcode.val()) {
                postalcode.addClass('is-invalid')
                error = true
            } else {
                postalcode.removeClass('is-invalid')
            }
            if (!error) {
                getPickupLocation();
            }

        });

        $('[name="existing_billing_address"]').on('change', function () {
            const $option = $('[name="existing_billing_address"] option:selected');
            let address = $option.attr('data-address');
            const isDefaultAddress = $option.attr('data-default') === 'true';
            address = address.replace(/,/g, '<br>');
            const $address = $('#existingBillingAddress').find('.address');
            $address.html(address);
            if (isDefaultAddress) {
                $address.append($('<br>')).append($('<span/>', { class: 'label label-info' }).text('Standaard factuuradres'));
            }
            updateOrder();
        });
        $('[name="existing_billing_address"]').trigger('change');

        $('[name="existing_shipping_address"]').on('change', function () {
            const $option = $('[name="existing_shipping_address"] option:selected');
            let address = $option.attr('data-address');
            const isDefaultAddress = $option.attr('data-default') === 'true';
            address = address.replace(/,/g, '<br>');
            const $address = $('#existingShippingAddress').find('.address');
            $address.html(address);
            if (isDefaultAddress) {
                $address.append($('<br>')).append($('<span/>', { class: 'label label-info' }).text('Standaard afleveradres'));
            }
            updateOrder();
        });
        $('[name="existing_shipping_address"]').trigger('change');

        //Check Address

        $('#checkoutButton').on('click', function (e) {
            const $form = $('#checkoutForm');
            const $shippingOption = $form.find('input[name="shipping_option"]:checked');

            var submit = false;

            if($shippingOption.val() == 'pickup_point'){
                submit = true;
            }

            if($shippingOption.val() == 'billing_address'){
                $('.billingRequired').each(function() {
                    if ( $(this).val() === '' )
                        submit = true;
                });
            }

            if($shippingOption.val() == 'shipping_address'){
                $('.shippingRequired').each(function() {
                    if ( $(this).val() === '' )
                        submit = true;
                });
            }

            if($('input[name="billing_address_option"]:checked').val() == 'existing' && $shippingOption.val() == 'billing_address'){
                submit = true;
            }

            if($('input[name="shipping_address_option"]:checked').val() == 'existing' && $shippingOption.val() == 'shipping_address'){
                submit = true;
            }

            if(submit){
                const $button = $(this);
                $button.attr('disabled', true)

                $.ajax({
                    type: 'GET',
                    url: url.cart.data,
                    dataType: 'json',
                    success: function(cart) {
                        // Construct the event data
                        const eventData = {
                            currency: "EUR",
                            value: parsePrice(cart.total),
                            coupon: cart.discount_coupon,
                            items: []
                        };

                        // Iterate over the cart items and populate the 'items' array in the event data
                        cart.rows.forEach((item, index) => {
                            const cartItem = {
                                item_id: item.product.id.toString(),
                                item_name: item.product.name,
                                affiliation: "microdose.nl",
                                index: index,
                                item_brand: "",
                                item_category: "",
                                price: parsePrice(item.price),
                                quantity: item.quantity
                            };
                            eventData.items.push(cartItem);
                        });

                        // Send the 'begin_checkout' event using gtag
                        gtag("event", "begin_checkout", eventData);
                    }
                });

                $('#checkoutForm').submit();
            }else{
                const details = getDetails($shippingOption.val());
                $('#formattedAddressInAddressModal').empty().append(details);

                const $modal = $('#addressModal');
                $modal.modal('show');
            }
        });

        $('#addressConfirmed').on('click', function (e){
            const $button = $(this);
            $button.attr('disabled', true)

            $('#checkoutForm').submit();
        });

        // Factuur / standaard afleveradres
        if ($('select[name="billing_country"]').length > 0 &&
            $('input[name="billing_postal_code"]').length > 0 &&
            $('input[name="billing_house_number"]').length > 0) {
            $('select[name="billing_country"]').on('change', function () {
                checkOrderPostalZip('billing');
            });
            $('input[name="billing_postal_code"], input[name="billing_house_number"]').on('keyup, blur', function () {
                checkOrderPostalZip('billing');
            });
        }
        checkOrderPostalZip('billing');

        // Afleveadres
        if ($('select[name="shipping_country"]').length > 0 &&
            $('input[name="shipping_postal_code"]').length > 0 &&
            $('input[name="shipping_house_number"]').length > 0) {
            $('select[name="shipping_country"]').on('change', function () {
                checkOrderPostalZip('shipping');
            });
            $('input[name="shipping_postal_code"], input[name="shipping_house_number"]').on('keyup, blur', function () {
                checkOrderPostalZip('shipping');
            });
        }
        checkOrderPostalZip('shipping');

        updateBillingAddress();
        updateShippingAddress();
        updateOrder();

        const $checkout = $('#checkout');
        const $shippingAddress = $('#shippingAddress');

        $checkout.on('update', function () {
            const $option = $('input[name="shipping_option"]:checked');
            switch ($option.val()) {
                case 'shipping_address':
                    $shippingAddress.find('input[type=radio], input, select').each(function () {
                        $(this).prop('disabled', false);
                    });
                    $shippingAddress.show();
                    break;
                case 'billing_address':
                case 'pickup_point':
                case 'pickup':
                    $shippingAddress.find('input, select').each(function () {
                        $(this).prop('disabled', true);
                    });
                    $shippingAddress.hide();
                    break;
            }
        });

        $('input[name="shipping_option"]').on('change', function () {
            $checkout.trigger('update');
        });

        $checkout.trigger('update');
    }
});

