$(function () {
    initProductOverview();
    initDetail();
    initSizeConfigurator();
    initStockNotification();
    $('[data-toggle=tooltip]').tooltip({
        content: function () {
            return $(this).prop('title');
        }
    });
});

if (!NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (fn, scope) {
        for (let i = 0, len = this.length; i < len; ++i) {
            fn.call(scope, this[i], i, this);
        }
    }
}

if (!Array.prototype.forEach) {
    Array.prototype.forEach = function (fn, scope) {
        for (let i = 0, len = this.length; i < len; ++i) {
            fn.call(scope, this[i], i, this);
        }
    }
}
Array.min = function (array) {
    return Math.min.apply(Math, array);
};
// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {

            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            let o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            let len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            let n = fromIndex | 0;

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                // c. Increase k by 1.
                // NOTE: === provides the correct "SameValueZero" comparison needed here.
                if (o[k] === searchElement) {
                    return true;
                }
                k++;
            }

            // 8. Return false
            return false;
        }
    });
}

let globalVariants = [];
let globalSelected = [];

function containsAll(needles, haystack) {
    for (let i = 0, len = needles.length; i < len; i++) {
        if ($.inArray(needles[i], haystack) === -1) return false;
    }
    return true;
}

function initProductOverview() {
    if ($('#products').length > 0) {
        initShoppingCart();

        let $button = $('#products .page-content .read-more');
        if ($('#products .page-content .text p').length > 1) {
            let height = $('#products .page-content .text').outerHeight();

            $('#products .page-content .text').css({
                height: $('#products .page-content .text').children('p').first().outerHeight()
            });

            $button.on('click', function () {
                $('#products .page-content .text').toggleClass('active');

                if ($('#products .page-content .text').hasClass('active')) {
                    $button.find('i').removeClass('icon-plus').addClass('icon-minus');
                    $button.find('span').text('Lees minder');

                    $('#products .page-content .text').css({
                        height: height
                    });
                } else {
                    $button.find('i').removeClass('icon-minus').addClass('icon-plus');
                    $button.find('span').text('Lees meer');

                    $('#products .page-content .text').css({
                        height: $('#products .page-content .text').children('p').first().outerHeight()
                    });
                }
            });
        } else {
            $button.hide();
        }

        $('.product .inner').matchHeight();

        $('.product').each(function () {
            const $product = $(this);

            const $image = $product.find('.image');

            $(this).find('.variants .slider .variant').click(function (e) {
                const $variant = $(this);
                e.preventDefault();

                $product.find('.variants .slider .variant').removeClass('slick-current');
                $variant.addClass('slick-current');

                const src = $variant.find('img').attr('src');

                $image.attr('src', src);
            });
            $(this).find('.variants .slider').slick({
                mobileFirst: true,
                vertical: true,
                verticalSwiping: true,
                focusOnSelect: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                prevArrow: '<i class="icon icon-chevron-up prev"></i>',
                nextArrow: '<i class="icon icon-chevron-down next"></i>',
                dots: false,
                infinite: false
            });

            $product.find('.variants .slider .variant [data-media-id=' + $image.data('media-id') + ']').parent().click();

            $(this).find('.variants .slider').on('click', function (event) {
                event.preventDefault();
            });
        });
    }
}

function initDetail() {
    if ($('#product').length > 0) {
        initDetailGallery();
        initChangeAmount();
        initShoppingCart();
        initImageLightbox();
        initVariants();
        initProductTabs();
        initUpsell();
        initExtraProduct();
        initAdvantage();
    }
}

function initDetailGallery() {
    $('#product .gallery .nav').show();
    if ($('#product .gallery').length > 0) {
        $('#product .gallery').slick({
            slidesToShow: 1,
            infinite: true,
            // asNavFor: '.thumbnails',
            prevArrow: $('.gallery-wrapper .prev'),
            nextArrow: $('.gallery-wrap .arrow')
        }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            var mySlideNumber = nextSlide;
            $('.thumbnails .slick-slide').removeClass('slick-active slick-current');
            $('.thumbnails .slick-slide').eq(mySlideNumber).addClass('slick-active slick-current');
        });

        if(window.outerWidth < 768){
            $('#product .thumbnails').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: '.gallery',
                focusOnSelect: false,
                arrows: false,
                infinite: true,
                vertical: false,
                verticalSwiping: false
            });
        }else{
            $('#product .thumbnails').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                asNavFor: '.gallery',
                focusOnSelect: false,
                arrows: false,
                infinite: true,
                vertical: true,
                verticalSwiping: true
            });
        }

        $('#product .thumbnails .slick-slide').on('click', function (event) {
            $('#product .gallery').slick('slickGoTo', $(this).data('slickIndex'));

            $('.thumbnails .slick-slide').removeClass('slick-active');
            $('.thumbnails .slick-slide').eq($(this).data('slickIndex')).addClass('slick-active');
        });
        // Remove active class from all thumbnail slides
        $('.thumbnails .slick-slide').removeClass('slick-active');

        // Set active class to first thumbnail slides
        $('.thumbnails .slick-slide').eq(0).addClass('slick-active');

        if ($('#product .gallery .nav .slide').length < 2) {
            $('#product .gallery .nav').hide();
        } else {
            $('#product .gallery .nav').slick({
                mobileFirst: true,
                slidesToShow: 2,
                infinite: false,
                centerMode: false,
                focusOnSelect: true,
                asNavFor: '#product .gallery .images',
                slide: '.slide',
                arrows: false,
                vertical: true,
                verticalSwiping: true,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4
                    }
                }]
            });

            $('#product .gallery .nav .prev-arrow').on('click', function () {
                $('#product .gallery .nav').slick('slickPrev');
            });
            $('#product .gallery .nav .next-arrow').on('click', function () {
                $('#product .gallery .nav').slick('slickNext');
            });
        }
    }

    $('[data-action="toggle-video-modal"]').on('click', function () {
        const $modal = $('#videoModal');
        const $video = $(this);

        const id = $video.data('id');

        $modal.find('iframe').attr('src', 'https://www.youtube.com/embed/' + id);
        $modal.modal('show');
    });
    hideArrow();
    $(window).resize(function () {
        hideArrow();
    });

}

function initChangeAmount() {
    $('#addToCartForm .up').on('click', function (ev) {
        ev.preventDefault();
        $('#addToCartForm #quantity').val(parseInt($('#addToCartForm #quantity').val()) + 1);
    });

    $('#addToCartForm .down').on('click', function (ev) {
        ev.preventDefault();
        if (parseInt($('#addToCartForm #quantity').val()) > 1) {
            $('#addToCartForm #quantity').val(parseInt($('#addToCartForm #quantity').val()) - 1);
        }
    });
}

function hideArrow() {

    var amountOfSlides = $('.slick-slide', '#special-gallery').length;
    var amountOfActiveSlides = $('.slick-active', '#special-gallery').length;

    if (amountOfSlides === amountOfActiveSlides) {
        $('.next-arrow', '#special-gallery').hide();
        $('.prev-arrow', '#special-gallery').hide();
    } else {
        $('.next-arrow', '#special-gallery').show();
        $('.prev-arrow', '#special-gallery').show();
    }
}

function initShoppingCart() {
    $('.btn_buy').on('click', function (e) {
        e.preventDefault();
        const $button = $(this);

        if ($button.hasClass('disabled')) {
            return;
        }

        if ($('#variant').val() !== 'null' && $('#variant').val() !== 'nostock') {
            const $form = $('#addToCartForm');
            const data = $form.serialize();

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function (cart) {
                    updateCart(cart);

                    const $quantity = $('#quantity');
                    const quantity = parseInt($quantity.val());

                    gtag("event", "add_to_cart", {
                        currency: "EUR",
                        value: $('#productCartModal').data('product-price'),
                        items: [
                            {
                                item_id: $('#productCartModal').data('product-id'),
                                item_name: $('#productCartModal').data('product-name'),
                                affiliation: "Microdose.nl",
                                index: 0,
                                item_brand: $('#productCartModal').data('product-brand'),
                                item_category: $('#productCartModal').data('product-category'),
                                //location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                                price: $('#productCartModal').data('product-price'),
                                quantity: quantity
                            }
                        ]
                    });

                    // dataLayer.push({
                    //     'event': 'addToCart',
                    //     'ecomm_prodid': $('#productCartModal').data('product-id'),
                    //     'ecomm_totalvalue': cart.total_plus_shipping
                    // });
                }
            });

            $('#productCartModal').modal('show');
        } else {
            $('#options .option').each(function (index) {
                const $option = $(this);
                const $select = $option.find('select');

                if ($select.val() === '') {
                    const $label = $option.find('label');
                    $label.addClass('danger');
                }
            });

            $('#options i').remove();

            if($('#variant').val() === 'nostock'){
                $('#options').append($('<i>', {class: 'danger'}).text($('html').attr('lang') == 'nl' ? 'Momenteel niet op voorraad' : 'Not in stock at the moment'));
            } else {
                $('#options').append($('<i>', {class: 'danger'}).text('Maak uw keuze uit de aangegeven opties'));
            }
        }
    });
    $('.add-to-cart-button').on('click', function (e) {
        e.preventDefault();

        if ($(this).hasClass('disabled')) {
            return;
        }

        const form = $(this).closest('form');
        const data = form.serialize();
        const variantInput = form.find('input[name="variant"]');
        $(this).attr('disabled', true);
        $(this).addClass('disabled');
        if (variantInput !== 'null') {
            $.ajax({
                type: 'POST',
                url: form.attr('action'),
                data,
                dataType: 'json',
                success: function (cart) {
                    updateCart(cart);
                }
            });
        }
    });

    var timeout = null;
    $('#quantity').on('change keyup', function () {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(function () {
            updateOptions(globalVariants, globalSelected);
        }, 1000);
    });
}

function initImageLightbox() {
    $('.images').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true
        }
    });

}


function initVariants() {
    const $variants = $(document).find('[data-variants]');
    const variants = JSON.parse($variants.text());

    updateOptions(variants);
}

function parseOptions(variants) {
    let options = [];

    variants.forEach(function (variant) {
        variant.options.forEach(function (vo) {
            let co;
            options.forEach(function (option) {
                if (option.name === vo.name) {
                    co = option;
                }
            });
            if (typeof co === 'undefined') {
                co = {
                    id: vo.id,
                    name: vo.name,
                    order: vo.order,
                    values: []
                };
                options.push(co);
            }
            let cov;
            co.values.forEach(function (value) {
                if (value.name === vo.value.name) {
                    cov = value;
                }
            });
            if (typeof cov === 'undefined') {
                cov = {
                    id: vo.value.id,
                    name: vo.value.name,
                    images: vo.value.images,
                    order: vo.value.order,
                    media: variant.media
                };
                co.values.push(cov);
            }
            co.values.sort((cov1, cov2) => cov1.order - cov2.order);
        });
    });

    options.sort((co1, co2) => co1.order - co2.order);

    return options;
}

function findCombinations(variants, selected) {
    const ids = [];

    selected.forEach(function (id) {
        variants.forEach(function (variant) {
            const vids = [];
            variant.options.forEach(function (vo) {
                vids.push(vo.value.id);
            });

            if (containsAll(selected, vids)) {
                vids.forEach(function (id) {
                    if (ids.indexOf(id) === -1) {
                        ids.push(id);
                    }
                });
            }
        });
    });
    return ids;
}

function getVariant(variants, selected) {
    let variant = null;
    variants.forEach(function (v) {
        const vids = [];
        v.options.forEach(function (vo) {
            vids.push(vo.value.id);
        });
        if (vids.every((val) => selected.includes(val))) {
            variant = v;
        }
    });
    return variant;
}

function getSelected() {
    const selected = [];
    $('#options').find('select option:selected').each(function () {

        let id = parseInt($(this).data('id'));
        selected.push(isNaN(id) ? null : id)
    });
    return selected;
}

function getSelectedMedia() {
    const selectedMedia = [];
    $('#options').find('select option:selected').each(function () {
        let media = parseInt($(this).data('media'));
        selectedMedia.push(isNaN(media) ? null : media)
    });
    return selectedMedia;
}

function getValueIdsForVariant(variant) {
    const values = [];
    variant.options.forEach(function (option) {
        values.push(option.value.id);
    });
    return values;
}

function findMatchingVariants(variants, selected) {
    const matching = [];
    variants.forEach(function (variant) {
        const vids = getValueIdsForVariant(variant);

        if (containsAll(selected, vids)) {
            matching.push(variant);
        }
    });
    return matching;
}

function getVariantBySize(variants, s) {
    let variant = null;
    variants.forEach(function (v) {
        v.options.forEach(function (vo) {
            if (vo.value.name == s) {
                variant = v;
            }
        });
    });
    return variant;
}

function getAvailableValues(option, variants, selected, currentOption) {
    const previousSelected = [];
    selected.forEach(function (value, i) {
        if (i < currentOption) {
            previousSelected.push(value);
        }
    });

    option.values.forEach(function (value) {
        value.disabled = !isActiveValue(value, variants, previousSelected);
    });

    const combinations = findCombinations(variants, previousSelected);
    if (combinations.length > 0) {
        const values = [];
        option.values.forEach(function (value) {
            if (combinations.indexOf(value.id) !== -1) {
                values.push(value);
            }
        });

        return values;
    }

    return option.values;
}

function getEnabledValues(option, variants, selected, currentOption) {
    const values = getAvailableValues(option, variants, selected, currentOption);

    let enabled = [];
    values.forEach(function (value) {
        if (value.disabled === false) {
            enabled.push(value);
        }
    });

    return enabled;
}

function getValueById(option, id) {
    for (let i = 0; i < option.values.length; i++) {
        const value = option.values[i];
        if (value.id === id) {
            return value;
        }
    }

    return null;
}

function isActiveValue(value, variants, previousSelected) {
    const matchingVariants = findMatchingVariants(variants, previousSelected);

    let active = false;
    matchingVariants.forEach(function (variant) {
        if (variant.active) {
            variant.options.forEach(function (option) {
                if (option.value.id === value.id) {
                    active = true;
                }
            });
        }
    });
    return active;
}

function selectVariant(variants, selected) {
    const variant = getVariant(variants, selected);
    const language = $('html').attr('lang');

    var stockCheck = true,
        maxStock = 0,
        bundleStocks = [],
        bundles = [];

    if ($(document).find('[data-bundles]').length) {
        const $bundles = $(document).find('[data-bundles]');
        bundles = JSON.parse($bundles.text());

        bundles.forEach(function (item) {
            var stock = item.variants[0].stock;
            if (stock <= 0) {
                stockCheck = false;
            }
            bundleStocks.push(stock)
        })

        maxStock = Array.min(bundleStocks);
    } else if (null !== variant) {
        // console.log(variant);
        if (variant.stock <= 0) {
            stockCheck = false;
        }else{
            stockCheck = true;
        }

        maxStock = variant.stock;
    }

    if (null !== variant && stockCheck) {
        $(document).ready(function() {
            $('#options i').remove();

            $('#inwinkelwagen').attr('disabled', false);
        });
        $('#variant').val(variant.id);

        const $price = $('<div/>', {class: 'price', id: 'selectedVariantPrice'});
        if (null !== variant.discount_price) {
            const $sale = $('<div/>', {class: 'sale'})
                .append($('<span/>', {class: 'old'}).text(variant.price));
            $price.append($sale).append($('<strong/>').html(variant.discount_price));
        } else {
            $price.append($('<strong/>').html(variant.price));
        }
        // $price.append($('<span/>').text(' Incl. BTW'));

        $('#selectedVariantPrice').replaceWith($price);

        const $quantity = $('#quantity');

        const quantity = parseInt($quantity.val());

        const $form = $('#addToCartForm');
        const $button = $form.find('.btn_buy');

        $button.addClass('disabled');


        if (quantity > maxStock) {
            if ($('#quantityMessage').length === 0) {
                $form.append($('<div>', {
                    class: 'alert alert-danger',
                    id: 'quantityMessage'
                }).text('Het maximum te bestellen aantal is ' + maxStock));
            }
            $quantity.val(maxStock);

            $button.removeClass('disabled');
        } else {
            $button.removeClass('disabled');
        }

        // Modal
        let options = '';
        options += '<div class="options">';
        variant.options.forEach(function (option) {

            var name = option.name;
            if (language === 'en' && option.name === 'Versie') {
                name = 'Version';
            }else if (language === 'en' && option.name === 'Inhoud') {
                name = 'Volume';
            }else if (language === 'en' && option.name === 'Gewicht') {
                name = 'Weight';
            }

            options += '<span class="option"><strong>' + name + '</strong>: ' + option.value.name + '</span>';
        });
        options += '</div>';

        $('#modalOptions').html(options);
        $('#modalPrice').replaceWith($price.clone());
    } else if (null !== variant) {
        $('#variant').val(variant.id);

        const $price = $('<div/>', {class: 'price', id: 'selectedVariantPrice'});
        if (null !== variant.discount_price) {
            const $sale = $('<div/>', {class: 'sale'})
                .append($('<span/>', {class: 'old'}).text(variant.price));
            $price.append($sale).append($('<strong/>').html(variant.discount_price));
        } else {
            $price.append($('<strong/>').html(variant.price));
        }
        // $price.append($('<span/>').text(' Incl. BTW'));

        $('#selectedVariantPrice').replaceWith($price);

        $(document).ready(function() {
            $('#options').append($('<i>', {class: 'danger'}).text($('html').attr('lang') == 'nl' ? 'Momenteel niet op voorraad' : 'Not in stock at the moment'));

            $('#inwinkelwagen').attr('disabled', true);
        });

        let deliveryTime = $('#addToCartForm').data('delivery-time');
        if (deliveryTime == '') {
            deliveryTime = ($('html').attr('lang') == 'nl' ? 'Dit product is tijdelijk niet op voorraad.' : 'Not in stock at the moment');
        }
        //$('#addToCartForm').replaceWith($('<div class="alert alert-info text-center">' + deliveryTime + '</div>'));
        $('#variant').val('nostock');
        $('.delivery_time_visible').hide();
    }
}

function updateOptions(variants, selected = []) {
    globalVariants = variants;
    globalSelected = selected;

    const options = parseOptions(variants);

    selectVariant(variants, selected);

    $('#options').empty();

    let currentOption = 0;
    options.forEach(function (option) {
        const language = $('html').attr('lang');

        var name = option.name;
        if (language === 'en' && option.name === 'Versie') {
            name = 'Version';
        }else if (language === 'en' && option.name === 'Inhoud') {
            name = 'Volume';
        }else if (language === 'en' && option.name === 'Gewicht') {
            name = 'Weight';
        }

        const $option = $('<div/>', {class: 'option'});
        const $wrapper = $('<div/>', {class: 'form-group'}).append($('<label/>').text(name));
        const $values = $('<div/>').appendTo($wrapper);
        const $select = $('<select/>', {id: option.name.toLowerCase(), class: 'form-control'}).appendTo($values);

        $('<option/>', {value: ''}).text((language === 'en' ? 'Choose your ' : 'kies je ') + name.toLowerCase())
            .appendTo($select);

        const enabled = getEnabledValues(option, variants, selected, currentOption);
        if (enabled.length == 1) {
            selected[currentOption] = enabled[0].id;

            selectVariant(variants, selected);
        }

        const values = getAvailableValues(option, variants, selected, currentOption);

        if (currentOption === 0 || null != selected[currentOption - 1]) {
            values.forEach(function (value) {
                if ((selected.indexOf(value.id) !== -1 || enabled.length === 1) && !value.disabled) {
                    value.selected = true;
                }

                let text = value.name;
                if (value.disabled) {
                    switch (option.name) {
                        case 'Kleur':
                            return;
                        case 'Maat':
                            text = value.name + (value.disabled ? ' (Niet meer leverbaar)' : '');
                            break;
                    }
                }

                let $value = $('<option/>', {value: value.id})
                    .attr('data-type', option.name)
                    .attr('data-id', value.id)
                    .attr('data-order', value.order)
                    .attr('data-media', value.media)
                    .attr('disabled', value.disabled)
                    .text(text)
                    .appendTo($select);

                if (value.selected) {
                    $value.attr('selected', true);
                }
            });

            $select.on('change', function () {
                const currentOption = $select.closest('.option').index();
                const selected = getSelected();
                const selectedMedia = getSelectedMedia();

                const imageSlide = document.querySelector('.gallery [data-media="' + selectedMedia[0] + '"]');

                if (imageSlide) {
                    $('#product .gallery').slick('slickGoTo', imageSlide.getAttribute('data-slick-index'));
                }

                $('#options select').each(function (index) {
                    const $select = $(this);

                    if (index > currentOption) {
                        selected[index] = null;
                    }
                });

                updateOptions(variants, selected);
            });
        } else {
            $select.attr('disabled', true);

            selected.forEach(function (value, i) {
                if (i > currentOption - 1) {
                    selected[i] = null;
                }
            });
        }

        if (option.name.toLowerCase() === 'kleur') {
            const $images = $('<div/>', {class: 'images'});
            values.forEach(function (value, i) {
                if (selected.indexOf(value.id) !== -1) {
                    value.selected = true;
                }

                if (value.disabled) {
                    return;
                }

                if (value.images.length > 0) {
                    const image = value.images[0];

                    const $img = $('<img/>', {src: image.small, alt: value.name, style: 'width: 50px;'});
                    const $image = $('<div/>', {class: 'image'}).append($img)
                        .attr('data-id', value.id);

                    if (value.selected) {
                        $image.addClass('selected');
                    }

                    $image.on('click', function () {
                        const $image = $(this);
                        const id = $image.data('id');

                        const value = getValueById(option, id);
                        if (null !== value) {
                            updateImages(value);
                        }

                        $select.val(id).trigger('change');

                        $image.parent().find('.image').removeClass('selected');
                        $image.addClass('selected');
                    });

                    $images.append($image);
                }
            });

            $option.append($images);
        } else if (option.name.toLowerCase() === 'maat') {
            const $productTip = $('[data-toggle="productTip"]').parent();
            const $clone = $productTip.clone();
            $productTip.hide();

            if ($productTip.length > 0) {
                $clone.show();
                $wrapper.append($clone);

                $clone.find('[data-toggle=productTip]').off().on('click', function (e) {
                    e.preventDefault();
                    $(this).parent().find('.tip').toggleClass('show');
                });
            }

            if ($('#sizeConfiguratorModal').length > 0) {
                $wrapper.append($('<a href="#" class="sizeconfig" data-toggle="modal" data-target="#sizeConfiguratorModal"><i class="icon icon-question-circle"></i> Bepaal je maat</a>'));
            }
        }

        $option.append($wrapper);

        $('#options').append($option);

        currentOption++;
    });
}

function updateImages(value) {
    const $imageContainer = $('#product .gallery .images');
    const $thumbContainer = $('#product .gallery .nav');

    try {
        $imageContainer.slick('unslick');
        $thumbContainer.slick('unslick');
    } catch (e) {
        //
    }

    $imageContainer.find('.slide').remove();

    const videos = [];
    $thumbContainer.find('.slide.video').each(function () {
        videos.push($(this).clone());
    });

    $thumbContainer.find('.slide').remove();

    value.images.forEach(function (image) {
        const $image = $('<img/>', {src: image.large, alt: value.name});
        const $imageLink = $('<a>', {href: image.large}).append($image).append($('<i/>', {class: 'icon icon-search-plus'}));
        const $imageSlide = $('<div>', {class: 'slide'}).append($imageLink);
        $imageContainer.append($imageSlide);

        const $thumb = $('<img/>', {src: image.small, alt: value.name});
        const $thumbSlide = $('<div>', {class: 'slide'}).append($thumb);
        $thumbContainer.append($thumbSlide);
    });

    videos.forEach(function (video) {
        $thumbContainer.append(video);
    });

    initDetailGallery();
}

function initSizeConfigurator() {
    if ($('#sizeConfiguratorModal').length > 0) {

        $('#sizeConfiguratorModal .modal-footer button').on('click', function (e) {
            if ($('#sizeConfiguratorModal #overview').is(':visible')) {
                e.preventDefault();
                $('#sizeConfiguratorModal #overview').fadeOut(function (i, el) {
                    $('#sizeConfiguratorModal #selection').fadeIn();
                });
                $('#sizeConfiguratorModal .modal-footer button').text('Bereken');
            }
        });

        $('#sizeConfiguratorModal form').on('submit', function (e) {
            e.preventDefault();

            const sizes = [];
            if ($('#sizeConfiguratorModal').data('combinations') == '') {
                $('#sizeConfiguratorModal select').each(function (i, el) {
                    $('[data-part=' + $(el).attr('name') + ']').text($(el).find('option:selected').text());
                    sizes.push($(el).val());
                });

                $('#sizeConfiguratorModal .calculated .result').text(sizes.join(" "));
            } else {
                const data = $('#sizeConfiguratorModal').data('combinations');

                const $select_x = $('#sizeConfiguratorModal select').eq(0);
                const $select_y = $('#sizeConfiguratorModal select').eq(1);

                const value_x = $select_x.find('option:selected').text();
                const value_y = $select_y.find('option:selected').text();

                $('[data-part=' + $select_x.attr('name') + ']').text(value_x);
                $('[data-part=' + $select_y.attr('name') + ']').text(value_y);

                $(data).each(function (i, el) {
                    if (el.option_x == value_x && el.option_y == value_y) {
                        sizes.push(el.value);
                        $('#sizeConfiguratorModal .calculated .result').text(el.value);
                        return false;
                    }
                });
            }

            $('#sizeConfiguratorModal #selection').fadeOut(function () {
                $('#sizeConfiguratorModal #overview').fadeIn();
                $('#sizeConfiguratorModal .modal-footer button').text('Bereken opnieuw');
            });

            const $variants = $(document).find('[data-variants]');
            const variants = JSON.parse($variants.text());
            const options = parseOptions(variants);

            const selected = getSelected();

            if (sizes.length > 0) {
                options.forEach(function (option, index) {
                    sizes.forEach(function (size) {
                        option.values.forEach(function (value) {
                            if (value.name === size) {
                                selected[index] = value.id;
                            }
                        });
                    });
                });
            }

            updateOptions(variants, selected);
        });
    }
}

function initProductTabs() {
    if ($('#tabsNav').length > 0) {
        $('#tabsNav li').on('click', function () {
            $('#tabsNav li').removeClass('active');
            $('#tabs .tab').removeClass('active');
            $(this).addClass('active');
            $('#tabs #' + $(this).data('tab')).addClass('active');
            $.fn.matchHeight._update()
        });
    }
}

function updateUpsellTotal() {
    const $inputs = $('#upsell .extra-products form input[id^="variant-"]');

    var total = 0;

    $inputs.each(function (i, input) {
        const $input = $(input);
        const price = parseFloat($input.attr('data-price').replace(',', '.'));

        if ($input.attr('type') === 'checkbox' && !$input.is(':checked')) {
            return;
        }

        total += price;
    });

    var formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
    });

    $('#upsellTotal').text(formatter.format(total));
}

function initExtraProduct() {
    const attachArray = [];
    const cartModal = $('#productCartModal');
    const cartForm = $('#addToCartForm');
    var input = $('<input type="hidden" name="bundle_products[]" data-id="">');

    $('.add-extra-product').on('click', function (ev) {
        ev.preventDefault();
        var id = $(this).data('id');

        input.data('id', id).val(id);
        cartForm.append(input);

        var parent = $(this).parent();
        parent.hide()
        parent.next().show();
        cartModal.find('.bundle-product[data-id=' + id + ']').addClass('active');

    })

    $('.delete-extra-product').on('click', function (ev) {
        var id = $(this).data('id');

        cartForm.find('input[data-id=' + id + ']').remove();

        var parent = $(this).parent();
        parent.hide()
        parent.prev().show();

        cartModal.find('.bundle-product[data-id=' + id + ']').removeClass('active');

    })
}

function initUpsell() {
    const $products = $('#upsell .extra-products .product');
    const $labels = $('#upsell .extra-products form label');

    $labels.each(function (i, label) {
        $(label).find('input').on('change', function () {
            $products.each(function (o, product) {
                if ($(product).data('id') == $(label).find('input').data('id')) {
                    $(product).toggleClass('active');
                }
            });
            updateUpsellTotal();
        });
    });

    $products.each(function (i, product) {
        $(product).on('click', function (ev) {
            ev.preventDefault();

            $labels.each(function (o, label) {
                if ($(label).find('input').data('id') == $(product).data('id')) {
                    $(label).find('input').trigger('click');
                }
            });
            // updateUpsellTotal();
        });
    });

    updateUpsellTotal();

    $('.btn_buy_more').on('click', function (e) {
        e.preventDefault();
        const $button = $(this);

        if ($button.hasClass('disabled')) {
            return;
        }

        if ($('#variant').val() !== 'null') {
            const $form = $('#addMoreForm');
            const data = $form.serialize();

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function (cart) {
                    updateCart(cart);
                }
            });

            const $modal = $('#upsellModal');
            const $upsell = $('#upsell');

            const $container = $modal.find('.upsell-products');
            $container.empty();

            const $bundleProducts = $upsell.find('.default, .product.active');

            $bundleProducts.each(function (i, product) {
                $container.append($(product).clone())
            });

            $modal.modal('show');
        }
    });
}

function initStockNotification() {
    const $modal = $('#stock-notification-modal');
    $('#stock-notification-button').on('click', function () {
        $modal.modal('show');
    })
    $('#stock-notification-form-button').on('click', function () {
        var error = false;
        $('#stock-notification-form input').each(function (idx, input) {

            if ($(input).val() == '') {
                error = true;
                $(input).addClass('is-invalid')
            } else {
                $(input).removeClass('is-invalid')
            }
        })

        if (!error) {
            var data = $('#stock-notification-form').serialize();

            $.ajax({
                type: 'POST',
                data: data,
                dataType: 'json',
                url: '/nl/product/stock/notification',
                success: function (result) {
                    if (result.success) {
                        $('#stock-notification-form .message').empty().append($('<div class="alert alert-success">' + result.message + '</div>'));
                        $('#stock-notification-form-button').remove();
                        $('#stock-notification-info').replaceWith($('<div class="alert alert-success text-center">Je wordt op de hoogte gehouden wanneer dit artikel weer op voorraad is.</div>'));
                    } else {
                        $('#stock-notification-form .message').empty().append($('<div class="alert alert-danger">' + result.message + '</div>'));
                    }

                }

            })
        }
    })
}

function initAdvantage() {
    const cartForm = $('#addToCartForm');

    $('.quantity-choose').on('click', function (e) {
        e.preventDefault();
        var quantity = $(this).data('quantity');
        var price = $(this).data('price');

        cartForm.find('#quantity').val(quantity);

        $('.quantity-choose').removeClass('active');
        if (quantity == 1) {
            $('#productCartModal').find('.price').text(price + ' Incl. BTW');
        } else {
            $('#productCartModal').find('.price').text(quantity + ' x ' + price + ' Incl. BTW');
        }
        $(this).addClass('active');
    })
}