$(function() {
    if ($('#gallery').length > 0) {
        gallery()
    }
});


function gallery() {
    var isSliding = false;


    $('#gallery .slick-carousel').slick({
        slidesToShow: 2,
        prevArrow: '<i class="icon icon-chevron-left prev"></i>',
        nextArrow: '<i class="icon icon-chevron-right next"></i>',
        infinite: false,
        responsive: [{
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                }
            },

        ]
    }).on('beforeChange', function() {
        isSliding = true;
    }).on('afterChange', function() {
        isSliding = false;
    });


    if ($('.lightbox').length > 0) {

        $('.slick-carousel').each(function() {
            var magnific = $(this).magnificPopup({
                delegate: 'a',
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
            magnific.on('click', function(e) {
                e.preventDefault();
            });

            $(".lightbox").on('click', function(event) {
                if (isSliding) {
                    event.preventDefault();
                    return;
                } else {
                    magnific.magnificPopup('open', $(this).attr('data-index'))
                }
            });

        });

    }
}