$(function() {
    if ($('#rate').length > 0) {
        init()
    }
});

function init() {
    var options = {
        max_value: 5,
        step_size: 0.5,
    }
    $('.rate').each(function(index) {
        $(this).prop("selectedIndex", -1);
        if ($(this).attr('data-value')) {
            $(this).prop("selectedIndex", $(this).attr('data-value') - 1);

        }
        $(this).barrating({
            theme: 'icomoon-stars'
                // initialRating: ($(this).attr('data-value') ? $(this).attr('data-value') : 0)
        });
    });

}