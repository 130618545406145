/**
 * Update cart.
 *
 * @param cart
 */
function updateCart(cart) {
    const $cart = $('#cart');

    if ($cart.length) {
        if (cart.rows.length) {
            const ids = [];
            $(cart.rows).each(function(i, el) {
                ids.push(el.id);
            });

            const containerTemplate = $('script[data-template="cart-container"]').text();

            const $tax = $('<div/>', { class: 'row tax' });
            cart.tax_data.forEach(function(entry) {
                $tax.append($('<div/>', { class: 'col-xs-8' }).html('<strong>BTW (' + entry.rate + '%)</strong>'))
                    .append($('<div/>', { class: 'col-xs-4 text-right' }).text(entry.calculated));
            });

            const $discount = $('<div/>');
            const $discountCoupon = $('<div/>', { class: 'row discount-coupon' });
            if (null === cart.discount_coupon) {
                $discountCoupon.append($('<div/>', { class: 'col-xs-8' }).append($('<a>').attr('data-action', 'enter-code').html('<i class="icon icon-chevron-right"></i> ' + (cart.language == 'nl' ? 'Code invoeren' : 'Insert code'))));
            } else {
                const $discountCouponDiscount = $('<div/>', { class: 'row discount' });

                if (cart.has_discount_coupon_discount) {
                    $discountCouponDiscount.append($('<div/>', { class: 'col-xs-8' }).text('Korting: ' + cart.discount_coupon.code.toUpperCase()))
                        .append($('<div/>', { class: 'col-xs-4 text-right' }).text('- ' + cart.discount_coupon_discount));
                }

                $discount.append($discountCouponDiscount);

                $discountCoupon.append($('<div/>')
                    .append($('<div/>', { class: 'col-xs-8' }).html('<span class="code"><b>' + cart.discount_coupon.code.toUpperCase() + '</b> toegepast</span>'))
                    .append($('<div/>', { class: 'col-xs-4' }).append($('<a/>', { class: 'remove' }).attr('data-action', 'remove-discount-coupon')
                        .html('&times;'))));
            }

            if (cart.has_club_discount) {
                const $clubDiscount = $('<div/>', { class: 'row discount' });

                $clubDiscount.append($('<div/>', { class: 'col-xs-8' }).text(cart.club_level))
                    .append($('<div/>', { class: 'col-xs-4 text-right' }).text('- ' + cart.club_discount));

                $discount.append($clubDiscount);
            }

            if (cart.has_product_tier_discount) {
                const $productTierDiscount = $('<div/>', { class: 'row discount' });

                $productTierDiscount.append($('<div/>', { class: 'col-xs-8' }).text('Actie korting'))
                    .append($('<div/>', { class: 'col-xs-4 text-right' }).text('- ' + cart.product_tier_discount));

                $discount.append($productTierDiscount);
            }

            const $container = $(containerTemplate.replace(/{{ id }}/g, cart.id)
                .replace(/{{ count }}/g, cart.count)
                .replace(/{{ discount_coupon }}/g, $discountCoupon[0].outerHTML)
                .replace(/{{ subtotal }}/g, cart.total_without_discount)
                .replace(/{{ discount }}/g, $discount[0].outerHTML)
                .replace(/{{ tax }}/g, $tax[0].outerHTML)
                // .replace(/{{ shipping_fee }}/g, cart.free_shipping ? '<span class="free-shipping">Gratis</span>' : cart.shipping_fee)
                .replace(/{{ total }}/g, cart.total));

            $cart.empty()
                .append($container);

            const $cartRows = $('.cart-rows');
            $cartRows.empty();
            const rowTemplate = $('script[data-template="cart-row"]').text();
            const rowTemplateBundle = $('script[data-template="cart-row-bundle"]').text();
            const currencyFormatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
            })
            $.each(cart.rows, function(index, row) {

                let image = '/img/nophoto.png';
                let name = 'Onbekend';
                var bundlesProducts;
                if (row.has_bundle) {
                    bundlesProducts = row.variant.bundles.map(function(elem) {
                        return '1x ' + elem.name;
                    }).join(", ");
                }
                if (null !== row.variant) {
                    name = row.variant.product.name;
                    if (row.variant.product.image) {
                        image = row.variant.product.image;
                    }
                } else if (null !== row.ledger) {
                    name = row.ledger.name;
                }

                let options = '';
                let fields = '';
                if (row.variant !== null && row.variant.options.length) {
                    const optionFieldTemplate = '<input type="hidden" name="options[{{ option }}]" value="{{ value }}">';
                    options += '<div class="options">';
                    $.each(row.variant.options, function(index, option) {
                        options += '<span class="option">' + option.value.name + '</span>';
                        fields += optionFieldTemplate.replace(/{{ option }}/g, option.id)
                            .replace(/{{ value }}/g, option.value.id);
                    });
                    options += '</div>';
                }

                let label = '';
                if (row.variant.product.label !== null && row.variant.product.label.key === 'pre-order') {
                    label += '<small style="margin-left: 10px;">(Pre-order)</small>';
                }

                var $row;
                if (row.has_bundle) {
                    $row = $(rowTemplateBundle.replace(/{{ id }}/g, row.id)
                        .replace(/{{ image }}/g, image)
                        .replace(/{{ name }}/g, name)
                        .replace(/{{ variant.options }}/g, options)
                        .replace(/{{ bundles.product }}/g, bundlesProducts)
                        .replace(/{{ bundles.difference }}/g, currencyFormatter.format(row.bundle_difference))
                        .replace(/{{ variant.sku }}/g, row.variant.sku)
                        .replace(/{{ variant.delivery_time }}/g, row.variant.delivery_time)
                        .replace(/{{ label }}/g, label)
                        .replace(/{{ price }}/g, row.price)
                        .replace(/{{ quantity }}/g, row.quantity)
                        .replace(/{{ fields }}/g, fields)
                        .replace(/{{ subtotal }}/g, row.subtotal)
                        .replace(/{{ url.product.show }}/g, null === row.variant ? '#' : row.variant.product.link)
                        .replace(/{{ url.cart.update }}/g, url.cart.update.replace(/@id/g, row.id))
                        .replace(/{{ url.cart.remove }}/g, url.cart.remove.replace(/@id/g, row.id)));
                } else {
                    $row = $(rowTemplate.replace(/{{ id }}/g, row.id)
                        .replace(/{{ image }}/g, image)
                        .replace(/{{ name }}/g, name)
                        .replace(/{{ variant.options }}/g, options)
                        .replace(/{{ variant.sku }}/g, row.variant.sku)
                        .replace(/{{ variant.delivery_time }}/g, row.variant.delivery_time)
                        .replace(/{{ label }}/g, label)
                        .replace(/{{ price }}/g, row.price)
                        .replace(/{{ quantity }}/g, row.quantity)
                        .replace(/{{ fields }}/g, fields)
                        .replace(/{{ subtotal }}/g, row.subtotal)
                        .replace(/{{ url.product.show }}/g, null === row.variant ? '#' : row.variant.product.link)
                        .replace(/{{ url.cart.update }}/g, url.cart.update.replace(/@id/g, row.id))
                        .replace(/{{ url.cart.remove }}/g, url.cart.remove.replace(/@id/g, row.id)));
                }


                // if (settings.register) {
                //     const $edit = $('<a/>', {class: 'btn btn-default btn-sm'})
                //         .data('action', 'edit-row')
                //         .data('price', row.raw_price)
                //         .text('Wijzigen');
                //
                //     $edit.on('click', function (e) {
                //         e.preventDefault();
                //
                //         const $modal = $('#editRowModal');
                //         const $button = $(this);
                //
                //         const price = $button.data('price');
                //
                //         $modal.find('[name="price"]').val(price);
                //         $modal.find('form').attr('action', url.cart.update.replace(/@id/g, row.id));
                //         $modal.modal('show');
                //     });
                //
                //     $row.find('.actions').prepend($edit)
                // }

                $cartRows.append($row);
            });

            $cartRows.on('cart.loaded', function() {
                $('[data-action="remove-row"]').off().on('click', function(e) {
                    e.preventDefault();
                    $.ajax({
                        type: 'DELETE',
                        url: $(this).attr('href'),
                        dataType: 'json',
                        success: function(cart) {
                            updateCart(cart);
                        }
                    });
                });

                bindCartOptions();
            });

            $cartRows.trigger('cart.loaded');
        } else {
            $cart.empty()
                .append($('script[data-template="cart-empty"]').html());
        }
    }

    $('[data-action="enter-code"]').off().on('click', function(e) {
        const $discountCoupon = $('.discount-coupon');

        const $discountCouponForm = $('<form>', { id: 'addDiscountCouponForm' }).append($('<div/>', { class: 'col-xs-6 col-md-4' })
                .append($('<input/>', {
                    type: 'text',
                    name: 'code',
                    class: 'form-control required'
                }))).append($('<div/>', { class: 'col-xs-6 col-md-4' })
                .append($('<button/>', {
                        type: 'button',
                        class: 'btn btn-discount-coupon primary'
                    }).attr('data-action', 'add-discount-coupon')
                    .text('+')))
            .append($('<div/>', { class: 'col-xs-12' })
                .append($('<b/>', { class: 'message' })));

        $discountCoupon.html($discountCouponForm);

        $('[data-action="add-discount-coupon"]').off().on('click', function(e) {
            e.preventDefault();

            const $container = $('.discount-coupon');
            const $input = $container.find('input[name="code"]');
            const $message = $container.find('.message');
            const code = $input.val();

            $message.removeClass('text-danger')
                .addClass('text-success')
                .text('De kortingscode wordt toegepast, een ogenblik geduld...');

            $.ajax({
                type: 'POST',
                url: url.cart.discountCoupon,
                data: {
                    code
                },
                dataType: 'json',
                success: function(cart) {
                    updateCart(cart);
                },
                error: function(e) {
                    const message = e.responseJSON && 'message' in e.responseJSON ? e.responseJSON.message : 'Er is iets misgegaan.';

                    $container.addClass('has-error');

                    $message.removeClass('text-success')
                        .addClass('text-danger')
                        .text(message);
                }
            });
        });

        $('#addDiscountCouponForm').off().on('submit', function(e) {
            e.preventDefault();

            $('[data-action="add-discount-coupon"]').trigger('click');
        });
    });

    $('[data-action="remove-discount-coupon"]').off().on('click', function(e) {
        e.preventDefault();
        $.ajax({
            type: 'DELETE',
            url: url.cart.discountCoupon,
            dataType: 'json',
            success: function(cart) {
                updateCart(cart);
            }
        });
    });

    $('#count').text(cart.count);
    $('#total').text(cart.total);
    $('#shippingFee').text();
}

/**
 * Bind Cart Options (Quantity increase/decrease).
 */
function bindCartOptions() {
    $('[data-action="quantity-decrease"]').off().on('click', function() {
        const $form = $(this).closest('form');
        const $input = $form.find('input[name="quantity"]');
        let quantity = 0;

        if (settings.register) {
            quantity = parseInt($input.val()) - 1;
        } else {
            quantity = parseInt($input.val()) <= 0 ? 0 : parseInt($input.val()) - 1;
        }

        $input.val(quantity)
            .trigger('cart.update');
    });

    $('[data-action="quantity-increase"]').off().on('click', function() {
        const $form = $(this).closest('form');
        const $input = $form.find('input[name="quantity"]');

        const quantity = parseInt($input.val()) + 1;

        $input.val(quantity)
            .trigger('cart.update');
    });

    let timeout = 0;
    $('input[name="quantity"]').off().on('cart.update', function() {
        const $input = $(this);

        const $decreaseButton = $(this).closest('form')
            .find('[data-action="quantity-decrease"]');

        let quantity = parseInt($input.val());

        const $form = $(this).closest('form');
        $decreaseButton.prop('disabled', quantity < 1);

        if (settings.register) {
            if (!isNaN(quantity) && quantity > 0) {
                $form.find('.not-in-cart-options').hide();
                $form.find('.in-cart-options').show();
            } else {
                $form.find('.in-cart-options').hide();
                $form.find('.not-in-cart-options').show();
            }
        } else {
            $decreaseButton.prop('disabled', quantity < 1);

            if (!isNaN(quantity) && quantity > 0) {
                $form.find('.not-in-cart-options').hide();
                $form.find('.in-cart-options').show();
            } else {
                $form.find('.in-cart-options').hide();
                $form.find('.not-in-cart-options').show();
                $input.val(quantity = 0);
            }
        }

        const data = $form.serialize();

        clearTimeout(timeout);
        timeout = setTimeout(function() {
            $.ajax({
                type: 'PUT',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function(cart) {
                    updateCart(cart);
                }
            });
        }, 500);

    }).on('keyup', function() {
        const $input = $(this);

        $input.trigger('cart.update');
    }).on('keydown', function(e) {
        /**
         * @link: https://css-tricks.com/snippets/javascript/javascript-keycodes/
         */
        if ($.inArray(e.which, [46, 8, 9, 109, 173]) !== -1 || // delete (46), backspace (8), tab (9)
            (e.which === 65 && (e.ctrlKey === true || e.metaKey === true)) || // ctrl + a (65) and command + a (65)
            (e.which >= 35 && e.which <= 40)) // end (35), home (36), left arrow (37), up arrow (38), right arrow (39), down arrow (40)
        {
            return;
        }

        if ((e.shiftKey || (e.which < 48 || e.which > 57)) && (e.which < 96 || e.which > 105)) { // not 0 - 9
            e.preventDefault();
        }
    });
}

function parsePrice(priceString) {
    const numericValue = parseFloat(priceString.replace(/[^0-9.-]+/g, ''));
    return isNaN(numericValue) ? 0 : numericValue;
}

$(document).ready(function() {
    $.ajax({
        type: 'GET',
        url: url.cart.data,
        dataType: 'json',
        success: function(cart) {
            updateCart(cart);
            // Construct the event data
            const eventData = {
                currency: "EUR",
                value: parsePrice(cart.total),
                items: []
            };

            console.log(cart);

            // Iterate over the cart items and populate the 'items' array in the event data
            cart.rows.forEach((item, index) => {
                const cartItem = {
                    item_id: item.variant.product.id.toString(),
                    item_name: item.variant.product.name,
                    affiliation: "microdose.nl",
                    index: index,
                    item_brand: "",
                    item_category: "",
                    price: parsePrice(item.price),
                    quantity: item.quantity
                };
                eventData.items.push(cartItem);
            });

            // Send the 'view_cart' event using gtag
            gtag("event", "view_cart", eventData);
        }
    });

    if ($('#addCustomSales').length) {
        $('#addCustomSalesForm').on('submit', function(e) {
            e.preventDefault();
            $('#addCustomSales').click();
        });

        $('#addCustomSales').on('click', function() {
            const $modal = $('#addCustomSalesModal');
            const $form = $('#addCustomSalesForm');
            const data = $form.serialize();

            $modal.modal('hide');
            $form[0].reset();

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function(cart) {
                    updateCart(cart);
                }
            });
        });
    }

    if ($('#editRow').length) {
        $('#editRow').on('click', function() {
            const $modal = $('#editRowModal');
            const $form = $('#editRowForm');
            const data = $form.serialize();

            $modal.modal('hide');
            $form[0].reset();

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function(cart) {
                    updateCart(cart);
                }
            });
        });
    }
});