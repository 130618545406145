$(function () {
    initNav();
    initSlideshow();
    initCarousel();
    initProductSlider();
    initEqualHeights();
    initTabs();
    initFaq();
    initCookiebox();
    initFavorite();
    initFooter();
    initBrandSlider();
    initSearch();
    initScrollButtons();
    initProductReviews();
    initReviewSlider();
    initAgeConfirmation();
    initHomeSlider();
    initFeaturedSlider();
    initUspSlider();
});

$(window).on('load', function () {
    initMatchheight();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        // initNav();
        initProductReviews();
        initEqualHeights();
        initFooter();
    }, 100));
});

$.ajaxSetup({
    headers: {
        [csrf.header]: csrf.token
    }
});

function initSlideshow() {
    $('#slideshow').slick({
        autoplay: true,
        arrows: false,
        fade: true,
        dots: false
    });
}

function initNav() {
    $('.menu-btn, header nav ul > li, .search-btn, .cart-btn, .user-btn').unbind('click');
    $('header nav ul').removeClass('sub-open').removeAttr('style');
    $('header nav ul li').removeClass('open').removeAttr('style');

    $('.language-btn').on('click', function () {
        console.log('hello');
        $('.languages .languages-dropdown').toggleClass('active');
    })
    // Navigation
    if ($(window).width() < 992) {
        $('header .top .usps ul').slick({
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false,
            slidesToShow: 1,
            dots: false
        });
        // Menu
        $('.menu-btn').on('click', function (e) {
            e.preventDefault();

            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $('header nav').slideDown(300, function () {
                    $('header nav ul li').each(function (i, el) {
                        if ($(el).children('ul').length) {
                            var height = $(el).outerHeight() + $(el).children('ul').outerHeight();
                            $(el).attr('data-height', height);
                        }
                    });
                });
            } else {
                closeNav($(this));
            }
        });

        $('header nav ul li a').on('click', function (e) {
            var $a = $(this);
            var $li = $a.parent();
            if ($li.children('ul').length) {
                e.preventDefault();
                $li.toggleClass('open');

                if ($li.hasClass('open')) {
                    $li.parent('ul').addClass('sub-open');

                    $('nav > .container > ul, li.open').css('height', $li.data('height'));

                    $('ul.sub-open').each(function (i, el) {
                        $(el).prev('a').addClass('hide');
                    });
                } else {

                    $li.parent('ul').removeAttr('style');
                    $li.parent('ul').prev('a').removeClass('hide');
                    $li.parent('ul').removeClass('sub-open');
                    $li.removeAttr('style');

                    var $last_open_li = $li.closest('li.open');

                    $('nav > .container > ul, li.open').css('height', $last_open_li.data('height'));
                }
            }
        });

    } else {
        if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
            $('header nav ul li').each(function (i, el) {
                var $el = $(el);
                $el.children('a').click(function (e) {
                    if (!$el.hasClass('clicked') && $el.has('ul').length > 0) {
                        e.preventDefault();
                        $el.addClass('clicked');
                    }
                });
            });
        }

        // User
        $('.user-btn').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $('header .user').fadeToggle(100);
        });

        $(document).mouseup(function (e) {
            var container = $('.user-btn, .user');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $('.user').hide();
                $('.user-btn').removeClass('active');
            }
        });

        // Fixed header
        $(document).scroll(function (e) {
            if ($(this).scrollTop() > 40) {
                $('header').addClass('fixed');
                $('body').addClass('fixed');
            } else {
                $('header').removeClass('fixed');
                $('body').removeClass('fixed');
            }
        });
    }
}

function closeNav(e) {
    e.removeClass('active');

    $('header nav').slideUp({
        duration: 300
    }).css({
        zIndex: 0
    });

    $('header nav ul').removeClass('sub-open').removeAttr('style');
    $('header nav ul li').removeClass('open').removeAttr('style');
}

function initHomeSlider() {
    if ($('#homeSlider').length > 0) {
        // Animate the first slide on initialization
        $('#homeSlider').on('init', function(event, slick){
            var firstSlide = $(slick.$slides.get(0));
            firstSlide.find('.content').addClass('animate-in-left');
            firstSlide.find('img').addClass('animate-in-right');
        });

        $('#homeSlider').slick({
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
            fade: true,
            dots: false
        });

        // Before the slide changes, remove animation classes from the current slide
        $('#homeSlider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            var slide = $(slick.$slides.get(currentSlide));
            slide.find('.content').removeClass('animate-in-left');
            slide.find('img').removeClass('animate-in-right');
        });

        // After the slide changes, add animation classes to the new slide
        $('#homeSlider').on('afterChange', function(event, slick, currentSlide){
            var slide = $(slick.$slides.get(currentSlide));
            slide.find('.content').addClass('animate-in-left');
            slide.find('img').addClass('animate-in-right');
        });
    }
}

function initUspSlider() {
    if ($('#uspSlider').length > 0) {
        $('#uspSlider').slick({
            autoplay: true,
            arrows: false,
            slidesToShow: 4,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }]
        });
    }
}

function initFeaturedSlider() {
    if ($('#featuredSlider').length > 0) {
        $('#featuredSlider').slick({
            autoplay: false,
            arrows: true,
            infinite: false,
            dots: false,
            prevArrow: $('.featuredprev'),
            nextArrow: $('.featurednext'),
            slidesToShow: 4,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }]
        });
    }
}

function initCarousel() {
    $('#carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: false
    });
}

function initProductSlider() {
    if ($('#home #products .product-slider').length > 0) {
        $('#home #products .product-slider').slick({
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<i class="icon icon-chevron-left prev"></i>',
            nextArrow: '<i class="icon icon-chevron-right next"></i>',

            responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1439,
                    settings: {
                        slidesToShow: 4
                    }
                }
            ]
        });
    }
}

function initEqualHeights() {
    if ($('.equalHeights').length) {
        $('.equalHeights').each(function () {
            var parent = $(this),
                items = parent.data('equals').replace(/ /g, '').split(','),
                height;

            items.forEach(function (item) {
                height = 0;

                parent.find(item).each(function () {
                    if ($(this).height() > height) {
                        height = $(this).height();
                    }
                });

                if (parent.hasClass('no-mobile') && $(window).width() < 767) {
                    // Do nothing...
                } else {
                    parent.find(item).css({
                        minHeight: height
                    });
                }
            });
        });
    }
}

function initTabs() {
    if ($('.tabs').length > 0) {
        $('.tabs nav ul .tab').each(function () {
            $(this).click(function () {
                $('.tabs nav ul .tab.active').removeClass('active');
                $(this).addClass('active');

                if ($(this).hasClass('active')) {
                    $('.tabs .tab-contents .tab-content.active').removeClass('active');
                    $('#' + $(this).data('tab')).addClass('active');
                }
            });
        });
    }
}

function initFaq() {
    if ($('.faq-wrap').length > 0) {
        const faqJson = document.getElementById('faqJson');
        const faqData = JSON.parse(faqJson.textContent.trim());
        const blocks = document.querySelectorAll('.faq-wrap .block');

        let chevron = '';
        if (window.innerWidth < 1200) {
            chevron = `<i class="icon-arrow-down"></i>`;
        } else {
            chevron = `<i class="icon-arrow-right"></i>`;
        }

        const groupList = document.createElement('ul');
        groupList.classList.add('group-list');

        Object.keys(faqData).forEach(function (groupName) {

            let questionIds = [];
            const groupQuestions = faqData[groupName];

            groupQuestions.forEach(function (question) {
                questionIds.push(question.id);
            });

            const groupItem = document.createElement('li');
            groupItem.classList.add('group');
            groupItem.dataset.group = groupName;
            groupItem.dataset.questionIds = JSON.stringify(questionIds);
            groupItem.innerHTML = `<h2>${groupName}</h2>${chevron}`;
            groupItem.addEventListener('click', showQuestions);
            groupList.appendChild(groupItem);
        });

        blocks[0].appendChild(groupList);

        function showQuestions(event) {
            blocks[2].innerHTML = '';
            blocks[1].classList.add('open');

            const questionPlaceholder = document.querySelector('.questionplaceholder');
            blocks[2].appendChild(questionPlaceholder.cloneNode(true));

            if (window.innerWidth < 1200) {
                window.scrollTo({
                    top: blocks[1].getBoundingClientRect().top + window.scrollY - 150,
                    behavior: 'smooth'
                });
            }

            const groups = document.querySelectorAll('.group-list .group');
            const currentGroup = event.currentTarget;
            const groupName = event.currentTarget.dataset.group;

            groups.forEach(function (groupEl) {
                groupEl.classList.remove('active');
            });
            currentGroup.classList.add('active');

            const questionList = document.createElement('ul');
            questionList.classList.add('question-list');

            const groupQuestions = faqData[groupName];
            console.log(groupQuestions);
            groupQuestions.forEach(function (question) {

                const questionItem = document.createElement('li');
                questionItem.classList.add('question');
                questionItem.dataset.questionId = question.id;
                questionItem.dataset.group = groupName;
                questionItem.innerHTML = `<h3>${question.question}</h3>${chevron}`;
                questionItem.addEventListener('click', function (event) {
                    const questions = document.querySelectorAll('.question-list .question');
                    const currentQuestion = event.currentTarget;

                    questions.forEach(function (questionEl) {
                        questionEl.classList.remove('active');
                    });
                    currentQuestion.classList.add('active');

                    const answerItem = document.createElement('div');
                    answerItem.classList.add('answer');
                    answerItem.innerHTML = question.answer;
                    blocks[2].innerHTML = '';
                    blocks[2].appendChild(answerItem);

                    if (window.innerWidth < 1200) {
                        window.scrollTo({
                            top: blocks[2].getBoundingClientRect().top + window.scrollY - 150,
                            behavior: 'smooth'
                        });
                    }
                });
                questionList.appendChild(questionItem);
            });

            blocks[1].innerHTML = '';
            blocks[1].appendChild(questionList);
        }

        $(window).on('load', function () {
            function getQueryParam(name) {
                const urlParams = new URLSearchParams(window.location.search);
                return urlParams.get(name);
            }

            const questionId = getQueryParam('id');

            const groups = document.querySelectorAll('.group-list .group');

            groups.forEach(function (groupEl) {
                const ids = JSON.parse(groupEl.dataset.questionIds);

                if (ids.includes(parseInt(questionId))) {
                    groupEl.click();

                    const questionEl = document.querySelector('[data-question-id="' + questionId + '"]');

                    if (questionEl) {
                        questionEl.click();
                    }
                }
            });

            if (questionId) {
                const questionEl = document.querySelector('[data-question-id="' + questionId + '"]');
            }
        });
    }
}

function initMatchheight() {
    $('.matchheight').matchHeight();
}

function initCookiebox() {
    if ($('#cookiebox').length && document.cookie.indexOf('cookiebox=') === -1) {
        setTimeout(function () {
            $('#cookiebox').hide().fadeIn('fast').css({
                bottom: 30
            });
        }, 500);

        $('#cookiebox .accept').click(function () {
            setCookie('cookiebox', 1, 365);
            $('#cookiebox').fadeOut().css({
                bottom: -230
            });
        });
    }
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

function removeCookie(name) {
    if (get_cookie(name)) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}

function initFavorite() {
    $('.product .favorite').on('click', function (e) {
        e.preventDefault();

        const $button = $(this);
        const id = $button.data('product');

        const data = {
            id
        };

        $.ajax({
            type: 'POST',
            url: $button.attr('href'),
            data,
            dataType: 'json',
            success: function (favorites) {
                if (typeof favorites[id] === 'undefined') {
                    if ($('#products').hasClass('favorites')) {
                        $button.parents('.product').fadeOut();

                        console.log($('.product').length);

                        if (favorites.length == 0) {
                            $('<div class="alert alert-info" style="margin-top: 30px;">Er zijn momenteel geen favorieten.</div>').appendTo($('.page-content'));
                        }
                    } else {
                        $button.removeClass('active')
                    }
                } else {
                    $button.addClass('active')
                }
            }
        });
    });
}

function showPopmessage(text) {
    $('.popmessage').text(text).addClass('active');

    setTimeout(function () {
        $('.popmessage').removeClass('active');
    }, 3000);
}

function initFooter() {
    if ($(window).width() < 768) {
        $('footer nav > ul > li').each(function () {
            $(this).find('h4').unbind().click(function () {
                $(this).toggleClass('active');
                $(this).parent().find('ul').slideToggle('fast');
            });
        });
    }
}

function initBrandSlider() {
    if ($('#brands').length > 0) {
        $('.brand-slider').slick({
            variableWidth: true,
            mobileFirst: true,
            arrows: false,
            prevArrow: '<i class="icon icon-chevron-left prev"></i>',
            nextArrow: '<i class="icon icon-chevron-right next"></i>',
            autoplay: true,
            autoplaySpeed: 5000
        });
    }
}

function delay(callback, ms) {
    var timer = 0;
    return function () {
        var context = this,
            args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

function initSearch() {
    const form = document.getElementById('searchForm');
    const input = form.querySelector('input');
    const results = document.getElementById('searchResults');
    const loader = document.createElement('i');
    const html = document.querySelector('html');
    loader.classList.add('loader', 'hide');
    results.append(loader);
    let source;
    let xhr;

    document.addEventListener('click', e => {
        let isClickInside = form.contains(e.target);

        if (!isClickInside) {
            results.innerText = '';
            results.classList.remove('show');
            input.value = '';
            html.style.overflow = 'initial';
        }
    });

    form.addEventListener('submit', e => {
        e.preventDefault();
    });

    const language = $('html').attr('lang');

    $(input).keyup(function (e) {
        if (xhr !== undefined) {
            xhr.abort();
        }

        loader.classList.remove('hide');

        let value = input.value;
        if (input.value.length > 1) {
            results.classList.add('show');

            xhr = $.ajax({
                type: 'POST',
                url: '/' + language + '/zoeken',
                data: {
                    searchterm: this.value
                },
                dataType: 'json',
                success: function (data) {
                    results.querySelectorAll('h3').forEach(h3 => {
                        h3.remove();
                    });
                    results.querySelectorAll('li').forEach(li => {
                        li.remove();
                    });

                    if ((data.products && data.products.length)
                        || (data.pages && data.pages.length)
                        || (data.blogs && data.blogs.length)
                        || (data.questions && data.questions.length)) {

                        if (data.questions.length) {
                            let h3 = document.createElement('h3');
                            h3.innerText = (language == 'nl' ? "Vragen" : 'Questions');
                            results.append(h3)
                        }
                        data.questions.forEach(question => {
                            let li = document.createElement('li');
                            let a = document.createElement('a');
                            a.setAttribute('href', question.url);
                            a.innerText = question.name

                            li.append(a);
                            results.append(li);
                        });

                        if (data.products.length) {
                            let h3 = document.createElement('h3');
                            h3.innerText = (language == 'nl' ? 'Product(en)' : 'Products');
                            results.append(h3)
                        }
                        data.products.forEach(product => {
                            let li = document.createElement('li');
                            let a = document.createElement('a');
                            a.setAttribute('href', product.url);
                            a.innerText = product.name

                            li.append(a);
                            results.append(li);
                        });
                        // if (data.pages.length) {
                        //     let h3 = document.createElement('h3');
                        //     h3.innerText = (language == 'nl' ? "Pagina's" : 'Pages');
                        //     results.append(h3)
                        // }
                        // data.pages.forEach(product => {
                        //     let li = document.createElement('li');
                        //     let a = document.createElement('a');
                        //     a.setAttribute('href', product.url);
                        //     a.innerText = product.name
                        //
                        //     li.append(a);
                        //     results.append(li);
                        // });

                        // if (data.categories.length) {
                        //     let h3 = document.createElement('h3');
                        //     h3.innerText = (language == 'nl' ? "Categorieën" : 'Categories');
                        //     results.append(h3)
                        // }
                        data.categories.forEach(category => {
                            let li = document.createElement('li');
                            let a = document.createElement('a');
                            a.setAttribute('href', category.url);
                            a.innerText = category.name

                            li.append(a);
                            results.append(li);
                        });

                        // if (data.blogs.length) {
                        //     let h3 = document.createElement('h3');
                        //     h3.innerText = "Blogs";
                        //     results.append(h3)
                        // }
                        //
                        // data.blogs.forEach(product => {
                        //     let li = document.createElement('li');
                        //     let a = document.createElement('a');
                        //     a.setAttribute('href', product.url);
                        //     a.innerText = product.name
                        //
                        //     li.append(a);
                        //     results.append(li);
                        // });

                        html.style.overflow = 'hidden';
                        loader.classList.add('hide');

                    } else {
                        let li = document.createElement('li');
                        let a = document.createElement('a');
                        a.innerText = 'Geen resultaten';
                        li.append(a);
                        results.append(li);
                        loader.classList.add('hide');
                    }
                }
            });
        } else {
            results.classList.remove('show');
            results.append(loader);
            results.querySelectorAll('li').forEach(li => {
                li.remove();
            });
        }
    });


};

function initScrollButtons() {
    const buttons = document.querySelectorAll('.scroll-button');

    if (buttons) {
        buttons.forEach((button, i) => {
            let subject = document.querySelector(button.getAttribute('href'));

            button.addEventListener('click', (ev) => {
                ev.preventDefault();

                window.scrollTo({
                    top: (subject.getBoundingClientRect().top + window.scrollY) - (document.querySelector('header').clientHeight + 30),
                    behavior: 'smooth'
                });
            });
        });
    }
}

function initProductReviews() {
    const trigger = document.querySelector('#reviews .btn');
    const wrapper = document.querySelector('#reviews #review-holder');

    if (wrapper && !wrapper.classList.includes('no-close')) {
        if (window.innerWidth < 768) {
            const height = wrapper.clientHeight;

            wrapper.style.height = 0;

            trigger.addEventListener('click', ev => {
                ev.preventDefault();

                wrapper.classList.toggle('active');

                if (wrapper.classList.contains('active')) {
                    wrapper.style.height = height + 'px';
                } else {
                    wrapper.style.height = 0;
                }
            });
        } else {
            wrapper.classList.remove('active');
            wrapper.style.height = height + 'px';
        }
    }
}

function initReviewSlider() {
    const wrapper = document.querySelector('#trustpilotContainer');

    if (document.querySelector('#homepage') && wrapper) {
        const slider = new Swiper(wrapper.querySelector('#reviewSlider'), {
            slidesPerView: 1,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            preventInteractionOnTransition: true,
            autoplay: {
                delay: 7000,
            }
        });

        slider.detachEvents();
    }
}

function initAgeConfirmation() {

    if (!window.localStorage.getItem('age-confirmed')) {
        $('#age-confirmation').css({'display': 'flex'});
    }

    $('#go-back').click(function () {
        window.history.back();
    });

    $('#confirm-age').click(function () {
        window.localStorage.setItem('age-confirmed', 'yes');
        $('#age-confirmation').hide();
    });
};